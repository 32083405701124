import * as Core from '@Core/index.js';

const DEFAULT_STORE = {
	data: {
		products: [],
		comments: []
	},
	loaded: {
		products: false,
		productDetails: {},
		lessonDetails: {},
		comments: {}
	}
};

// STORE DEFINITIONS -------------------
/**
 * @exports Student_Store_Product
 * @namespace Student_Store_Product
 */
export const useProductStore = Core.Pinia.defineStore({
	id: 'product',

	state: () => ({
		...Core.Utils.cloneObject(DEFAULT_STORE)
	}),

	actions: {
		/**
		 * Retrieve all products from the API for current school
		 * @memberof Student_Store_Product
		 * @param {boolean} [force=false] force refresh
		 */
		async fetchProducts(force = false) {
			if (this.loaded.products && !force) return; // do nothing if already loaded

			const response = await Core.Api.get('student/products');
			const results = response.body.message || [];

			this.data.products = results;
			this.loaded.products = true;
		},

		/**
		 * Retrieve details for single course
		 * @memberof Student_Store_Product
		 * @param {string} slug course shortname
		 * @param {boolean} [force=false] force refresh
		 * @returns {Promise<boolean>} true if found
		 */
		async fetchProductDetails(slug, force = false) {
			const product = this.getProductBySlug(slug);

			// if doesn't exists already (no need to call the backend to check that)
			if (!product.id) return false;

			// check if content needs loading from API (5 minutes expiry)
			if (this.loaded.productDetails[product.id] > new Date().getTime() - 300000 && !force) {
				return true;
			}

			// handle loading of extra details
			const response = await Core.Api.get(`student/products-details/${product.id}`);
			if (response.type === 'CONFLICT') {
				return false;
			}

			const responseData = response.body.message || {};
			for (let i = 0; i < this.data.products.length; i++) {
				if (this.data.products[i].id === product.id) {
					this.data.products[i] = responseData;
					this.loaded.productDetails[product.id] = new Date().getTime();
					this.loaded.lessonDetails = {};
					return true;
				}
			}
		},

		/**
		 * Fetch lessons details
		 * @memberof Student_Store_Product
		 * @param {number} lessonId lesson ID
		 * @param {string} slug product slug
		 * @param {boolean} [force=false] force refresh
		 * @returns {Promise<boolean>} true if found
		 */
		async fetchLesson(lessonId, slug, force = false) {
			const lesson = this.getLesson(slug, lessonId);

			// check if content needs loading if it's not forced and it's not expired 5 minutes
			if (this.loaded.lessonDetails[lesson.id] > new Date().getTime() - 300000 && !force) {
				return true;
			}

			// get data from API and populate them
			const response = await Core.Api.get(`student/products-details-lesson/${lessonId}`);
			const lessonDetails = response.body.message;

			// catch errors from API
			if (response.type === 'CONFLICT') {
				return false;
			}

			// update existing lesson data
			const product = this.getProductBySlug(slug);

			// find lesson by id
			const lessonIndex = product.lessons.findIndex(
				(lessonItem) => lessonItem.id === parseInt(lessonId)
			);

			// merge existing lesson assignments with the new one before then merging existing lessons and new lesson data
			// existing one has progress data, new one has all the details, questions, content etc.
			const mergedAssignments = [];
			for (let i = 0; i < product.lessons[lessonIndex].assignments.length; i++) {
				const assignment = lessonDetails.assignments.find(
					(assignmentItem) =>
						assignmentItem.id === product.lessons[lessonIndex].assignments[i].id
				);
				const currentAssignment = {
					...product.lessons[lessonIndex].assignments[i],
					...assignment
				};
				mergedAssignments.push(currentAssignment);
			}

			product.lessons[lessonIndex] = {
				...product.lessons[lessonIndex],
				...lessonDetails,
				assignments: mergedAssignments
			};

			this.loaded.lessonDetails[lesson.id] = new Date().getTime();
			return true;
		},

		/**
		 * Fetch comments for a lesson
		 * @memberof Student_Store_Product
		 * @param {number} lessonId lesson ID
		 * @param {number} pageNo page number
		 * @param {boolean} [force=false] force refresh
		 * @returns {Promise<boolean>} true if found
		 */
		async fetchComments(lessonId, pageNo, force = false) {
			// check if content needs loading if it's not forced and it's not expired 5 minutes
			if (
				this.loaded.comments[lessonId] &&
				this.loaded.comments[lessonId][pageNo] > new Date().getTime() - 300000 &&
				!force
			) {
				return true;
			}

			const response = await Core.Api.get(`student/comments/${lessonId}/${pageNo}`);
			const comments = response.body.message;

			for (const comment of comments) {
				comment.firstname = comment.firstname || 'Anonymous';
				comment.lastname = comment.lastname || 'User';

				for (const reply of comment.replies) {
					reply.firstname = !reply.userId ? 'Teacher' : reply.firstname || 'Anonymous';
					reply.lastname = !reply.userId ? 'Reply' : reply.lastname || 'User';
				}
			}

			this.data.comments[lessonId] = this.data.comments[lessonId] || {};
			this.data.comments[lessonId][pageNo] = comments;

			this.loaded.comments[lessonId] = this.loaded.comments[lessonId] || {};
			this.loaded.comments[lessonId][pageNo] = new Date().getTime();
		}
	},

	getters: {
		/**
		 * Return all courses
		 * @memberof Student_Store_Product
		 * @param {object} state automatically passed in
		 * @returns {Array} data with all courses
		 */
		getCourses: (state) => {
			return state.data.products?.filter((product) => product.type === 'COURSE') || {};
		},

		/**
		 * Return all downloads
		 * @memberof Student_Store_Product
		 * @param {object} state automatically passed in
		 * @returns {Array} data with all digital downloads
		 */
		getDownloads: (state) => {
			return state.data.products.filter((product) => product.type === 'DOWNLOAD') || {};
		},

		/**
		 * Return all articles
		 * @memberof Student_Store_Product
		 * @param {object} state automatically passed in
		 * @returns {Array} data with all digital articles
		 */
		getArticles: (state) => {
			return state.data.products.filter((product) => product.type === 'ARTICLE') || {};
		},

		/**
		 * Return one product based on ID and type
		 * @memberof Student_Store_Product
		 * @param {object} state automatically passed in
		 * @returns {Function} object, expecting ID and TYPE
		 */
		getProductById: (state) => {
			return (id) => {
				return state.data.products?.find((product) => product.id === parseInt(id)) || {};
			};
		},

		/**
		 * Return product details by slug
		 * @memberof Student_Store_Product
		 * @param {object} state automatically passed in
		 * @returns {Function} object, expecting ID and TYPE
		 */
		getProductBySlug: (state) => {
			return (slug) => {
				return state.data.products.find((prod) => prod.slug === slug) || {};
			};
		},

		/**
		 * Return lesson details by product slug and lesson ID
		 * @memberof Student_Store_Product
		 * @returns {Function} Array data with a given lesson details (expects product slug and lesson ID)
		 */
		getLesson: function () {
			return (productSlug, lessonId) => {
				const product = this.getProductBySlug(productSlug);
				return product.lessons?.find((lesson) => lesson.id === parseInt(lessonId));
			};
		},

		/**
		 * Return assignment details by product slug, lesson ID and assignment ID
		 * @memberof Student_Store_Product
		 * @returns {Function} array with data with a given assignment details (provide product slug, lesson ID and assignment ID)
		 */
		getAssignment: function () {
			return (productSlug, lessonId, assignmentId) => {
				const lesson = this.getLesson(productSlug, lessonId);
				return lesson.assignments?.find(
					(assignment) => assignment.id === parseInt(assignmentId)
				);
			};
		},

		/**
		 * returns all comments for lesson
		 * @param {object} state automatically passed in
		 * @returns {Function} Array comments for lesson, expects lessonId and pageNo
		 */
		getComments: (state) => {
			return (lessonId, pageNo) => {
				return state.data.comments[lessonId]?.[pageNo] || [];
			};
		},

		/**
		 * Gets all categories
		 * @memberof Student_Store_Product
		 * @param {object} state automatically passed in
		 * @returns {Array} returns all categories from all products
		 */
		getAllCategories: (state) => {
			let allCategories = [];
			for (let i = 0; i < state.data.products.length; i++) {
				allCategories = [...allCategories, ...state.data.products[i].categories];
			}

			// return unique records
			return [...new Set(allCategories)];
		},

		/**
		 * Gets all authors
		 * @memberof Student_Store_Product
		 * @param {object} state automatically passed in
		 * @returns {Array} returns all authors from all products
		 */
		getAllAuthors: (state) => {
			const allAuthors = [];

			for (let i = 0; i < state.data.products.length; i++) {
				allAuthors.push(state.data.products[i].author);
			}

			// return unique records
			return [...new Set(allAuthors)];
		}
	}
});
