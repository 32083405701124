<template>
	<div>
		<div
			class="BpForms BpCheckbox"
			:class="stateComputed.currentClasses"
			:data-testid="id + '-checkbox'">
			<input
				:id="state.id"
				ref="checkboxInput"
				v-model="computedValue"
				class="BpCheckbox--input"
				:name="state.id"
				:value="state.checkedValue || true"
				type="checkbox"
				:disabled="disabled"
				:required="required"
				:aria-required="required"
				data-testid="checkbox" />
			<label
				:for="state.id"
				class="BpCheckbox--label"
				tabindex="0"
				@keypress.enter.space.prevent="$refs.checkboxInput.click()">
				<div class="BpCheckbox--check">
					<svg
						width="18px"
						height="18px"
						viewBox="0 0 18 18">
						<path
							d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"></path>
						<polyline points="1 9 7 14 15 4"></polyline>
					</svg>
				</div>

				<p
					class="BpCheckbox--labelTxt"
					:for="id">
					<span v-html="state.label"></span>
					<span
						v-if="state.tooltip"
						class="Tooltip Tooltip-bottom"
						:data-tooltip="state.tooltip"
						><span class="Tooltip-icon BpForms--tooltip"></span
					></span>
				</p>
			</label>
		</div>
	</div>
</template>

// -------------------------------------- SCRIPT --------------------------
<script>
	import * as Core from '@Core/index.js';
	import { useLanguageStore } from '@Core/store/language.js';
	import * as Helpers from './helpers.js';

	export default {
		name: 'BlueprintCheckbox',

		//  ---------- PROPS ----------
		props: {
			/**
			 * property {string} id - unique id
			 * @namespace Core_Blueprint_Checkbox
			 * @property {string} id - unique id
			 */
			id: {
				type: String,
				required: true
			},

			/**
			 * property {Array | object | string | boolean} [value] - value or values you want to pass in
			 * @namespace Core_Blueprint_Checkbox
			 * @property {Array | object | string | boolean} [value] - value or values you want to pass in
			 */
			value: {
				type: [Array, Object, String, Boolean],
				required: false,
				default: undefined
			},

			/**
			 * property {string | number} [checkedValue] - what to return once it is checked (only works for collections/array)
			 * @namespace Core_Blueprint_Checkbox
			 * @property {string | number} [checkedValue] - what to return once it is checked (only works for collections/array)
			 */
			checkedValue: {
				type: [String, Number],
				required: false,
				default: undefined
			},

			/**
			 * property {string} label - label, or title
			 * @namespace Core_Blueprint_Checkbox
			 * @property {string} label - label, or title
			 */
			label: {
				type: String,
				required: true
			},

			/**
			 * property {boolean} [required] - is this field required?
			 * @namespace Core_Blueprint_Checkbox
			 * @property {boolean} [required] - is this field required?
			 */
			required: {
				type: Boolean,
				required: false
			},

			/**
			 * property {string} [msgType='notification'] - type of the message (allowed: 'notification', 'warning', 'success', 'error')
			 * @namespace Core_Blueprint_Checkbox
			 * @property {string} [msgType='notification'] - type of the message (allowed: 'notification', 'warning', 'success', 'error')
			 */
			msgType: {
				type: String,
				required: false,
				validator: function (value) {
					return ['notification', 'warning', 'success', 'error'].includes(value);
				},
				default: 'notification'
			},

			/**
			 * property {string} [tooltip] - content to be shown as tooltip within label area
			 * @namespace Core_Blueprint_Checkbox
			 * @property {string} [tooltip] - content to be shown as tooltip within label area
			 */
			tooltip: {
				type: String,
				required: false,
				default: undefined
			},

			/**
			 * property {boolean} [showValidationText=true] - do we want validation text to be shown
			 * @namespace Core_Blueprint_Input
			 * @property {boolean} [showValidationText=true] - do we want validation text to be shown
			 */
			showValidationText: {
				type: Boolean,
				required: false,
				default: true
			},
			/**
			 * property {boolean} [disabled=false] - is this field disabled?
			 * @namespace Core_Blueprint_Checkbox
			 * @property {boolean} [disabled=false] - is this field disabled?
			 */
			disabled: {
				type: Boolean,
				required: false,
				default: false
			},
			/**
			 * property {boolean} [inGroup=false] - is this field part of a group (update styles if yes) ?
			 * @namespace Core_Blueprint_Checkbox
			 * @property {boolean} [inGroup=false] - is this field part of a group?
			 */
			inGroup: {
				type: Boolean,
				required: false,
				default: false
			}
		},

		//  ---------- EMITS ----------
		emits: ['update:value'],

		//  ---------- SETUP ----------
		setup(props, context) {
			const language = useLanguageStore();

			// reactive props, that's all
			const state = Core.Vue.computed(() => {
				return {
					...props, // make all props reactive
					type: 'checkbox'
				};
			});

			// internal values only (can be set from inside or outside)
			const internal = Core.Vue.reactive({
				currentMsgType: state.value.msgType,
				value: state.value.value
			});

			// our computed and transformed values to use as we need (from props)
			const stateComputed = Core.Vue.computed(() => {
				return {
					currentClasses: [
						Helpers.resolveClassNames(state.value, internal),
						props.inGroup && 'BpCheckbox-inGroup'
					]
				};
			});

			// this field's update process looks a bit different and it's using computedValue set & get methods
			// or it will break when you have multiple-choice checkbox (array value)
			const computedValue = Core.Vue.computed({
				get() {
					return props.value;
				},
				set(newValue) {
					context.emit('update:value', newValue);
					setTimeout(() => {
						validate();
					}, 25);
				}
			});

			// allow update to internals from outside via props
			Core.Vue.watch(
				() => [props.msgType],
				(msgTypeNew, msgTypePrev) => {
					if (msgTypeNew !== msgTypePrev) {
						internal.currentMsgType = msgTypeNew;
					}
				}
			);

			/* -------------------- UPDATE & VALIDATE FUNCTIONALITY ---------------------- */
			/**
			 * validate the field
			 * @returns {boolean} validation result
			 */
			function validate() {
				return Helpers.validators(state, internal);
			}

			/**
			 * Reset field to initial state
			 *
			 */
			function reset() {
				Helpers.resetAll(internal, state);

				//Let the component where its used to be aware of the reset value
				context.emit('update:value', state.value.value);
			}

			return {
				state,
				stateComputed,
				internal,
				language,
				computedValue,
				validate,
				reset
			};
		}
	};
</script>

// -------------------------------------- STYLES -----------------
<style lang="scss">
	@include block('BpCheckbox') {
		@include modifier('inGroup') {
			padding-bottom: 0;
		}
		@include element('label') {
			&:hover,
			&:focus {
				color: var(--color-secondary);
			}

			&:hover .BpCheckbox--check,
			&:focus .BpCheckbox--check {
				&:before {
					opacity: 1;
				}
				& svg {
					stroke: var(--color-secondary);
				}
			}
		}

		@include element('labelTxt') {
			display: inline-block;
			cursor: pointer;
			position: relative;
			margin-left: 30px;
			width: 100%;
		}

		@include element('check') {
			cursor: pointer;
			position: absolute;
			width: 18px;
			height: 18px;
			-webkit-tap-highlight-color: transparent;
			transform: translate3d(0, 0, 0);
			margin-top: 2px;

			&:before {
				content: '';
				position: absolute;
				top: -6px;
				left: -6px;
				width: 30px;
				height: 30px;
				border-radius: 50%;
				background: var(--color-grey800);
				opacity: 0;
				transition: opacity 0.2s ease;
			}

			& svg {
				position: relative;
				z-index: 1;
				fill: none;
				stroke-linecap: round;
				stroke-linejoin: round;
				stroke: var(--color-primary);
				stroke-width: 1.5;
				transform: translate3d(0, 0, 0);
				transition: all 0.2s ease;
			}
			& svg path {
				stroke-dasharray: 60;
				stroke-dashoffset: 0;
			}
			& svg polyline {
				stroke-dasharray: 22;
				stroke-dashoffset: 66;
			}
		}

		@include element('input') {
			display: none;

			&:checked + .BpCheckbox--label .BpCheckbox--check svg {
				stroke: var(--color-primary);
			}
			&:checked + .BpCheckbox--label .BpCheckbox--check svg path {
				stroke-dashoffset: 60;
				transition: all 0.3s linear;
			}
			&:checked + .BpCheckbox--label .BpCheckbox--check svg polyline {
				stroke-dashoffset: 42;
				transition: all 0.2s linear;
				transition-delay: 0.15s;
			}
		}

		@include element('msg') {
			display: inline-block;
			top: 3px;
		}

		// STATES
		&.is-error {
			.BpCheckbox--check svg {
				stroke: var(--color-stateDanger);
			}
			.BpCheckbox--label {
				color: var(--color-stateDanger);
			}
		}

		&.is-warning {
			.BpCheckbox--check svg {
				stroke: var(--color-stateWarning);
			}
			.BpCheckbox--label {
				color: var(--color-stateWarning);
			}
		}

		&.is-success {
			.BpCheckbox--check svg {
				stroke: var(--color-stateSuccess);
			}
			.BpCheckbox--label {
				color: var(--color-stateSuccess);
			}
		}

		&.is-disabled {
			.BpCheckbox--labelTxt {
				cursor: not-allowed;
				color: var(--color-grey600);
			}
			.BpCheckbox--check {
				pointer-events: none;
				opacity: 0.5;
				&:before {
					background: none;
				}
			}
		}
	}
</style>
