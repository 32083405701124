/**
 @module Core/Cookie
 */

const ScTokens = [];

// -------------------------------------- PRIVATE METHODS ----------------------------------------------
/**
 * Figure stuff out :)
 * @private
 * @param {string} url convert into params
 * @returns {object} all params in form of array
 */
function deparam(url) {
	const vars = {},
		urlParams = url.indexOf('?') > -1 ? url.split('?')[1] : url;

	let hash, i;

	const hashes = urlParams.split('&');
	for (i = 0; i < hashes.length; i++) {
		hash = hashes[i].split('=');
		vars[hash[0]] = decodeURIComponent(hash[1]).replace(/\+/g, ' ');
	}
	return vars;
}

/**
 * Convert cookie array into a string
 * @private
 * @param {Array} params array to convert
 * @returns {string} all params in form of string
 */
function param(params) {
	const output = [];
	for (const item in params) {
		output.push(`${encodeURIComponent(item)}=${encodeURIComponent(params[item])}`);
	}
	return output.join('&');
}

// -------------------------------------- PUBLIC METHODS ----------------------------------------------
/**
 * Retuns value of a selected cookie name
 * @param {string} name - name of the cookie
 * @returns {string|boolean} cookie value
 */
export function get(name) {
	let i, x, y;
	const ARRcookies = document.cookie.split(';');

	for (i = 0; i < ARRcookies.length; i++) {
		x = ARRcookies[i].substring(0, ARRcookies[i].indexOf('='));
		y = ARRcookies[i].substring(ARRcookies[i].indexOf('=') + 1);
		x = x.replace(/^\s+|\s+$/g, '');
		if (x === name) {
			const cookie = decodeURIComponent(y);

			if (cookie === 'true') {
				return true;
			} else if (cookie === 'false') {
				return false;
			} else {
				return cookie;
			}
		}
	}
	return undefined;
}

/**
 * Set regular unsecure cookie in a browser
 * @param {string} name - name of the cookie
 * @param {string} value - value of the cookie
 * @param {number} [exdays=356] - expiration time (in days)
 * @param {number} [extime] - expiration time (in miliseconds). Overrides exdays
 * @param {boolean} [crossdomain=false] - Should the cookie be stored across subdomains.
 * @param {string} [token] - Don't use. Needed to set cookie as SuperCookie.
 * @returns {object} with cookie value
 */
export function set(name, value, exdays = 356, extime, crossdomain = false, token) {
	if (typeof value === 'undefined' || typeof name === 'undefined') {
		return false;
	}

	const exdate = new Date();
	if (extime && extime > 0) {
		exdate.setTime(exdate.getTime() + extime);
	} else {
		exdate.setDate(exdate.getDate() + exdays);
	}

	let cValue =
		encodeURIComponent(value) +
		(exdays === null ? '; path=/' : `; expires=${exdate.toUTCString()}; path=/; SameSite=Lax;`);

	if (crossdomain) {
		// Matches domain even with multiple subdomains, see http://stackoverflow.com/questions/12447003/jquery-remove-subdomain-or-www
		let domain = window.location.hostname.match(/[^\.]*\.[^.]*$/);
		if (domain) {
			// it won't be there for localhost, but this won't work there anyway
			domain = domain[0];
		}
		cValue += `; domain=${domain}`;
	}

	// set cookie, but don't if it's a supercookie without a valid token
	if ((name === 'superCookie' || name === 'SuperCookie') && ScTokens.indexOf(token) < 0) {
		window.app.log(
			'COOKIE',
			'Trying to set SuperCookie using regular cookie method. This is not allowed, dude.'
		);
		return false;
	} else {
		document.cookie = `${name}=${cValue}`;
	}

	// display window.app.log messages about cookies
	if (name === 'superCookie') {
		window.app.log(
			'COOKIE',
			'superCookie set',
			`${name}: ${decodeURIComponent(value).replace(new RegExp('\\+', 'g'), ' ')}`
		);
	} else {
		window.app.log('COOKIE', `"${name}" set or modified`, {
			name: name,
			value: value,
			days: exdays
		});
	}

	return {
		cookieValue: cValue
	};
}

/**
 * Allows to set value for a selected key-value pari from the supercookie (strinigified cookie to hold "simple" flags)
 * @param {string} name - name of the cookie
 * @returns {string|boolean|undefined} cookie value
 */
export function superGet(name) {
	// get superCookie & change into array
	const tempCookie = get('superCookie');

	try {
		const finalCookie = decodeURIComponent(
			tempCookie.match(new RegExp(`${name}=([^&]*)`))[1].replace(new RegExp('\\+', 'g'), ' ')
		);
		if (finalCookie === '') {
			return;
		} else {
			if (finalCookie === 'true') {
				return true;
			} else if (finalCookie === 'false') {
				return false;
			} else {
				return finalCookie;
			}
		}
	} catch (e) {
		return;
	}
}

/**
 * Sets key-value pair in the supercookie (strinigified cookie to hold "simple" flags)
 * @param {string} name - name of the cookie
 * @param {string} value - value of the cookie
 */
export function superSet(name, value) {
	// set token to allow this superCookie change
	const currentToken = Math.floor(Math.random() * 100000 + 1);
	ScTokens.push(currentToken);

	// get superCookie & read into array
	let tempCookie = get('superCookie');
	if (!tempCookie) {
		tempCookie = {};
	} else {
		tempCookie = deparam(tempCookie);
	}

	// cookie configured in config
	if (name) {
		// modify array using name, matched against index from nvFrame.Config.superCookie

		if (typeof value !== 'undefined') {
			tempCookie[name] = value;
		} else {
			// remove if unset
			delete tempCookie[name];
		}

		// check total length and make sure there is no errors in formatting and set it
		const newCookie = param(tempCookie);

		if (newCookie.length > 4000) {
			window.app.log('COOKIE', 'superCookie is too long!');
		} else {
			set('superCookie', newCookie, 365, false, false, currentToken);
		}
	}
}
