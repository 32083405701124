<template>
	<div>
		<div
			class="BpForms BpSelect"
			:data-testid="id + '-select'"
			:class="stateComputed.currentClasses">
			<label
				v-if="state.label"
				class="BpForms--label"
				:for="id"
				>{{ state.label }}
				<span
					v-if="state.tooltip"
					class="Tooltip Tooltip-bottom"
					tabindex="0"
					:data-tooltip="state.tooltip"
					data-testid="tooltip">
					<span class="Tooltip-icon BpForms--tooltip"></span>
				</span>
				<span class="BpForms--optional">{{ language.string.cForms.optional }}</span>
			</label>

			<div
				class="BpForms--input BpSelect--selected"
				:class="{ 'is-open': internal.isOpen }"
				tabindex="0"
				data-testid="blueprint-select-selected"
				@click="toggleDropdown"
				@keypress.enter.space.prevent="toggleDropdown">
				<div
					v-if="state.icon"
					:class="state.icon"
					class="BpSelect--icon"></div>
				{{ stateComputed.displayName }}
				<input
					v-if="internal.isOpen"
					id="searchPhrase"
					ref="searchPhraseField"
					v-model="internal.searchPhrase"
					data-testid="search-box"
					class="BpSelect--searchBox"
					name="searchPhrase" />
				<transition name="fade">
					<div
						v-if="!internal.isOpen"
						class="iconFont-chevron BpSelect--iconDown"></div>
				</transition>
			</div>

			<transition name="fade">
				<div
					v-if="internal.isOpen"
					class="BpForms--suggestionsContainer"
					data-testid="blueprint-select-suggestions">
					<div
						:id="id + '-suggestions'"
						class="BpForms--suggestions">
						<template
							v-for="(option, index) of stateComputed.optionsFiltered"
							:key="index">
							<div
								v-if="option.name !== '---'"
								class="BpForms--suggestionItem"
								:class="state.value === option.value && 'is-selected'"
								data-testid="blueprint-select-suggestion"
								@click="
									updateValue(option.value);
									toggleDropdown();
								">
								{{ option.name }}
							</div>

							<hr
								v-else
								class="BpSelect--breakline" />
						</template>
					</div>
				</div>
			</transition>

			<transition name="slide-down">
				<div
					v-if="internal.currentMsg"
					class="BpForms--msg">
					{{ internal.currentMsg }}
				</div>
			</transition>
		</div>
	</div>
</template>

// -------------------------------------- SCRIPT ----------------------------------------------
<script>
	import * as Core from '@Core/index.js';
	import { useLanguageStore } from '@Core/store/language.js';
	import * as Helpers from './helpers.js';

	export default {
		name: 'BlueprintSelect',

		//  ---------- PROPS ----------
		props: {
			/**
			 * property {string} id - unique id
			 * @namespace Core_Blueprint_Select
			 * @property {string} id - unique id
			 */
			id: {
				type: String,
				required: true
			},

			/**
			 * property {string} [label] - label, or title
			 * @namespace Core_Blueprint_Select
			 * @property {string} [label] - label, or title
			 */
			label: {
				type: String,
				required: false,
				default: null
			},

			/**
			 * property {string | number | boolean | null | undefined} value - bind your value
			 * @namespace Core_Blueprint_Select
			 * @property {string | number | boolean | null | undefined} value - bind your value
			 */
			value: {
				type: [String, Number, Boolean, null, undefined],
				required: false,
				default: null
			},

			/**
			 * roperty {Array} options - array of objects (name:String, value:String)
			 * @namespace Core_Blueprint_Select
			 * @property {Array} options - array of objects (name:String, value:String)
			 */
			options: {
				type: Array,
				required: true
			},

			/**
			 * property {string | number} [placeholder] - placeholder text
			 * @namespace Core_Blueprint_Select
			 * @property {string | number} [placeholder] - placeholder text
			 */
			placeholder: {
				type: [String, Number],
				required: false,
				default: undefined
			},

			/**
			 * property {boolean} [disabled=false] - should this field be "disabled"
			 * @namespace Core_Blueprint_Select
			 * @property {boolean} [disabled=false] - should this field be "disabled"
			 */
			disabled: {
				type: Boolean,
				required: false
			},

			/**
			 * property {boolean} [required=false] - is this field required?
			 * @namespace Core_Blueprint_Select
			 * @property {boolean} [required=false] - is this field required?
			 */
			required: {
				type: Boolean,
				required: false
			},

			/**
			 * property {string} [icon] iconfont class name (full)
			 * @namespace Core_Blueprint_Select
			 * @property {string} [icon] iconfont class name (full)
			 */
			icon: {
				type: String,
				required: false,
				default: null
			},

			/**
			 * property {string} [msg] - message to display below the field
			 * @namespace Core_Blueprint_Select
			 * @property {string} [msg] - message to display below the field
			 */
			msg: {
				type: String,
				required: false,
				default: null
			},

			/**
			 * property {string} [msgType=notification] - type of the message (one of: 'notification', 'warning', 'success', 'error')
			 * @namespace Core_Blueprint_Select
			 * @property {string} [msgType=notification] - type of the message (one of: 'notification', 'warning', 'success', 'error')
			 */
			msgType: {
				type: String,
				required: false,
				validator: function (value) {
					return ['notification', 'warning', 'success', 'error'].includes(value);
				},
				default: 'notification'
			},

			/**
			 * property {string} [tooltip] - content to be shown as tooltip within label area
			 * @namespace Core_Blueprint_Select
			 * @property {string} [tooltip] - content to be shown as tooltip within label area
			 */
			tooltip: {
				type: String,
				required: false,
				default: null
			},

			/**
			 * property {boolean} [showValidationText=true] - do we want validation text to be shown
			 * @namespace Core_Blueprint_Input
			 * @property {boolean} [showValidationText=true] - do we want validation text to be shown
			 */
			showValidationText: {
				type: Boolean,
				required: false,
				default: true
			},
			/**
			 * property {Function} [customValidate] - overrides validation function
			 * @namespace Core_Blueprint_Radio
			 * @property {Function} [customValidate] - overrides validation function
			 */
			customValidate: {
				type: Function,
				required: false,
				default: undefined
			}
		},

		//  ---------- EMITS ----------
		emits: ['update:value'],

		//  ---------- SETUP ----------
		setup(props, context) {
			const language = useLanguageStore();
			const searchPhraseField = Core.Vue.ref();

			// reactive props, that's all
			const state = Core.Vue.computed(() => {
				return {
					...props // make all props reactive
				};
			});

			// internal values only (can be set from inside or outside)
			const internal = Core.Vue.reactive({
				currentMsg: state.value.msg,
				currentMsgType: state.value.msgType,
				isOpen: false, // controls dropdown show/hide
				searchPhrase: ''
			});

			// our computed and transformed values to use as we need (from props)
			const stateComputed = Core.Vue.computed(() => {
				return {
					currentClasses: Helpers.resolveClassNames(state.value, internal),
					optionsFiltered: (function () {
						// Options list (filtered on all) depending on search performed
						if (internal.searchPhrase.length > 0) {
							return state.value.options.filter((item) => {
								return (
									item.name
										.toLowerCase()
										.indexOf(internal.searchPhrase.toLowerCase()) > -1
								);
							});
						} else {
							return state.value.options;
						}
					})(),
					displayName: (function () {
						// displayName is using key of the array, rather than value, hence we needed it
						for (const item in state.value.options) {
							if (state.value.options[item].value === state.value.value) {
								return state.value.options[item].name;
							}
						}
						return state.value.placeholder || '';
					})()
				};
			});

			// allow update to internals from outside via props
			Core.Vue.watch(
				() => [props.msg, props.msgType],
				([msgNew, msgTypeNew], [msgPrev, msgTypePrev]) => {
					if (msgNew !== msgPrev) {
						internal.currentMsg = msgNew;
					}
					if (msgTypeNew !== msgTypePrev) {
						internal.currentMsgType = msgTypeNew;
					}
				}
			);

			/**
			 * Toggle dropdown list off and on, after checking if it's not disabled field
			 */
			function toggleDropdown() {
				if (!state.value.disabled) {
					internal.isOpen = !internal.isOpen;

					// focus on searchfield on open, or reset the phrase on close
					if (internal.isOpen === true) {
						// timeout is needed to allow the UI to update before focusing on the field
						setTimeout(() => {
							searchPhraseField.value?.focus();
							window.addEventListener('click', addOutsideClick);
						}, 100);
					} else {
						internal.searchPhrase = '';
						window.removeEventListener('click', addOutsideClick);
					}
				}
			}

			/**
			 * Adds click action for a listeener if click happened outside of the suggestions dropdown
			 * @param {object} e event passed from the listener
			 */
			function addOutsideClick(e) {
				if (!document.getElementById(`${props.id}-suggestions`)?.contains(e.target)) {
					toggleDropdown();
				}
			}

			/* -------------------- UPDATE & VALIDATE FUNCTIONALITY ---------------------- */
			/**
			 * updateValue the value from input, validate if needed
			 * @param {string | boolean | number} newValue value selected from the dropdown list to be set
			 */
			function updateValue(newValue) {
				context.emit('update:value', newValue);

				// needs timeout otherwise the state.value is "old one".
				// the emit above is not synchronous
				setTimeout(() => {
					validate();
				}, 25);
			}

			/**
			 * Reset field to initial state
			 *
			 */
			function reset() {
				Helpers.resetAll(internal, state);

				//Let the component where its used to be aware of the reset value
				context.emit('update:value', state.value.value);
			}

			/**
			 * validate the field
			 * @returns {boolean} validation result
			 */
			function validate() {
				return props.customValidate
					? props.customValidate(state, internal)
					: Helpers.validators(state, internal);
			}

			return {
				state,
				stateComputed,
				internal,
				language,
				toggleDropdown,
				updateValue,
				validate,
				reset,
				searchPhraseField
			};
		}
	};
</script>

// -------------------------------------- STYLES ----------------------------------------------
<style lang="scss">
	@include block('BpSelect') {
		position: relative;

		@include element('selected') {
			padding-left: 15px;
			padding-right: 35px;
			white-space: nowrap;
			cursor: pointer;
			overflow: hidden;

			text-overflow: ellipsis;

			&.is-open {
				border-bottom: 0;
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
			}
		}

		@include element('icon') {
			left: 10px;
			position: absolute;
			top: 13px;
			font-size: 20px;
		}

		@include element('iconDown') {
			position: absolute;
			right: var(--space-single);
			top: 17px;
		}

		@include element('breakline') {
			margin-top: 5px;
			margin-bottom: 5px;
		}

		@include element('searchBox') {
			position: absolute;
			left: -15px;
			padding-left: var(--space-single);
			top: 0%;
			border: none;
			margin-left: var(--space-single);
			padding-top: 10px;
			width: 100%;
			height: 50px;

			&:focus,
			&:active {
				outline: none;
			}
		}
	}
</style>
