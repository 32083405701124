<template>
	<div>
		<div
			class="BpForms"
			:class="stateComputed.currentClasses"
			:data-testid="id + '-textarea'">
			<label
				v-if="state.label"
				class="BpForms--label"
				:for="id">
				{{ state.label }}
				<span
					v-if="state.tooltip"
					class="Tooltip Tooltip-bottom"
					:data-tooltip="state.tooltip"
					><span class="Tooltip-icon BpForms--tooltip"></span
				></span>
				<span class="BpForms--optional">{{ language.string.cForms.optional }}</span>
			</label>
			<textarea
				:id="state.id"
				v-model.trim="state.value"
				class="BpForms--input BpForms--input-textarea"
				data-testid="textarea"
				:name="state.id"
				:placeholder="state.placeholder"
				:rows="state.rows"
				:required="state.required"
				:disabled="state.disabled"
				:maxlength="state.maxLength"
				:aria-required="state.required"
				@change="updateValue(false)"
				@keyup="updateValue(true)"></textarea>

			<div
				v-if="state.maxLength"
				class="BpForms--maxLimit"
				data-testid="max-limit">
				{{ stateComputed.charCount }} / {{ state.maxLength }}
			</div>

			<transition name="slide-down">
				<div
					v-if="internal.currentMsg"
					data-testid="message"
					class="BpForms--msg">
					{{ internal.currentMsg }}
				</div>
			</transition>
		</div>
	</div>
</template>

// -------------------------------------- SCRIPT ----------------------------------------------
<script>
	import * as Core from '@Core/index.js';
	import { useLanguageStore } from '@Core/store/language.js';
	import * as Helpers from './helpers.js';

	export default {
		name: 'BlueprintTextarea',

		// ---------- PROPS ----------
		props: {
			/**
			 * property {string} id - unique id
			 * @namespace Core_Blueprint_Textarea
			 * @property {string} id - unique id
			 */
			id: {
				type: String,
				required: true
			},

			/**
			 * property {string | number | null | undefined} [value] - bind value
			 * @namespace Core_Blueprint_Textarea
			 * @property {string | number | null | undefined} [value] - bind value
			 */
			value: {
				type: [String, Number, null, undefined],
				required: false,
				default: undefined
			},

			/**
			 * property {string} [label] - label, or title
			 * @namespace Core_Blueprint_Textarea
			 * @property {string} [label] - label, or title
			 */
			label: {
				type: String,
				required: false,
				default: undefined
			},

			/**
			 * property {string} [placeholder] - placeholder text
			 * @namespace Core_Blueprint_Textarea
			 * @property {string} [placeholder] - placeholder text
			 */
			placeholder: {
				type: String,
				required: false,
				default: undefined
			},

			/**
			 * property {boolean} [disabled] - should this field be "disabled"
			 * @namespace Core_Blueprint_Textarea
			 * @property {boolean} [disabled] - should this field be "disabled"
			 */
			disabled: {
				type: Boolean,
				required: false
			},

			/**
			 * property {boolean} [required=false] - is this field required?
			 * @namespace Core_Blueprint_Textarea
			 * @property {boolean} [required=false] - is this field required?
			 */
			required: {
				type: Boolean,
				required: false
			},

			/**
			 * property {string} [msg] - message to display below the field
			 * @namespace Core_Blueprint_Textarea
			 * @property {string} [msg] - message to display below the field
			 */
			msg: {
				type: String,
				required: false,
				default: undefined
			},

			/**
			 * property {string} [msgType=notification] - type of the message (one of: 'notification', 'warning', 'success', 'error')
			 * @namespace Core_Blueprint_Textarea
			 * @property {string} [msgType=notification] - type of the message (one of: 'notification', 'warning', 'success', 'error')
			 */
			msgType: {
				type: String,
				required: false,
				validator: function (value) {
					return ['notification', 'warning', 'success', 'error'].includes(value);
				},
				default: 'notification'
			},

			/**
			 * property {number} [rows] - height in # of rows
			 * @namespace Core_Blueprint_Textarea
			 * @property {number} [rows] - height in # of rows
			 */
			rows: {
				type: Number,
				required: false,
				default: undefined
			},

			/**
			 * property {string} [validation] - validation regex to follow
			 * @namespace Core_Blueprint_Textarea
			 * @property {string} [validation] - validation regex to follow
			 */
			validation: {
				type: String,
				required: false,
				default: undefined
			},

			/**
			 * property {string} [maxLength=500] - max number of characters
			 * @namespace Core_Blueprint_Textarea
			 * @property {string} [maxLength=500] - max number of characters
			 */
			maxLength: {
				type: Number,
				required: false,
				default: 500
			},

			/**
			 * property {string} [minLength=15] - min number of characters
			 * @namespace Core_Blueprint_Textarea
			 * @property {string} [minLength=15] - min number of characters
			 */
			minLength: {
				type: Number,
				required: false,
				default: 15
			},

			/**
			 * property {string} [tooltip] - content to be shown as tooltip within label area
			 * @namespace Core_Blueprint_Textarea
			 * @property {string} [tooltip] - content to be shown as tooltip within label area
			 */
			tooltip: {
				type: String,
				required: false,
				default: undefined
			},
			/**
			 * property {boolean} [showValidationText=true] - do we want validation text to be shown
			 * @namespace Core_Blueprint_Textarea
			 * @property {boolean} [showValidationText=true] - do we want validation text to be shown
			 */
			showValidationText: {
				type: Boolean,
				required: false,
				default: true
			}
		},

		//  ---------- EMITS ----------
		emits: ['update:value'],

		//  ---------- SETUP ----------
		setup(props, context) {
			const language = useLanguageStore();

			// reactive props, that's all
			const state = Core.Vue.computed(() => {
				return {
					...props // make all props reactive
				};
			});

			// internal values only (can be set from inside or outside)
			const internal = Core.Vue.reactive({
				currentMsg: state.value.msg,
				currentMsgType: state.value.msgType
			});

			// our computed and transformed values to use as we need (from props)
			const stateComputed = Core.Vue.computed(() => {
				return {
					currentClasses: Helpers.resolveClassNames(state.value, internal),
					charCount: state.value.value?.length ?? 0 / state.value.maxLength
				};
			});

			// allow update to internals from outside via props
			Core.Vue.watch(
				() => [props.msg, props.msgType],
				([msgNew, msgTypeNew], [msgPrev, msgTypePrev]) => {
					if (msgNew !== msgPrev) {
						internal.currentMsg = msgNew;
					}
					if (msgTypeNew !== msgTypePrev) {
						internal.currentMsgType = msgTypeNew;
					}
				}
			);

			/* -------------------- UPDATE & VALIDATE FUNCTIONALITY ---------------------- */
			let debounce = null; // to handle debounce
			/**
			 * updateValue the value from input, validate if needed
			 * @param {boolean} [delayed=false] if we need to debounce, set to true
			 * @returns {boolean} false if validation failed
			 */
			function updateValue(delayed = false) {
				// If delayed set, call this function in 300ms just without delay to execute the second part of the code
				if (debounce) clearTimeout(debounce);
				if (delayed) {
					debounce = setTimeout(() => {
						updateValue();
					}, 750);
					return true;
				}

				// executed only if called without the "delayed"
				context.emit('update:value', state.value.value);
				validate();

				return true;
			}

			/**
			 * Reset field to initial state
			 *
			 */
			function reset() {
				Helpers.resetAll(internal, state);
				//Let the component where its used to be aware of the reset value
				context.emit('update:value', state.value.value);
			}

			/**
			 * validate the field
			 * @returns {boolean} validation result
			 */
			function validate() {
				return Helpers.validators(state, internal);
			}

			return {
				state,
				stateComputed,
				internal,
				reset,
				language,
				updateValue,
				validate
			};
		}
	};
</script>

// -------------------------------------- STYLES ----------------------------------------------
<style lang="scss">
	@include block('BpTextarea') {
	}
</style>
