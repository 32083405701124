/**
 @module Core/Storage
 */

let supported;

// -------------------------------------- PRIVATE METHODS ----------------------------------------------
// check if local storage is supported
supported = function () {
	if (supported) {
		return supported;
	}

	const testKey = 'test';

	try {
		window.localStorage.setItem('testKey', testKey);
		supported = window.localStorage.getItem('testKey') === testKey;
		window.localStorage.removeItem('testKey');
		return supported;
	} catch (e) {
		return false;
	}
};

// -------------------------------------- PUBLIC METHODS ----------------------------------------------
/**
 * Set permanent local storage data
 * @param {string} key data key
 * @param {object} [data] data you want to store
 * @returns {string} - true/false for get, data for set
 */
export function local(key, data) {
	if (supported()) {
		// might fail in random places
		if (typeof data === 'undefined') {
			try {
				return JSON.parse(window.localStorage.getItem(key));
			} catch (e) {
				return window.localStorage.getItem(key) || null;
			}
		} else {
			try {
				if (data === null) {
					window.localStorage.removeItem(key);
				} else if (typeof data === 'string') {
					window.localStorage.setItem(key, data);
					window.app.log('STORAGE', `LOCAL ${key} has been set:`, data);
				} else {
					const stringified = JSON.stringify(data);
					window.localStorage.setItem(key, stringified);
					window.app.log('STORAGE', `LOCAL ${key} has been set:`, stringified);
				}
			} catch (e) {
				// Do nothing
			}
		}
	}

	return false;
}

/**
 * Get session local storage data
 * @param {string} key data key
 * @param {string} [data] data you want to store
 * @returns {string} - true/false for get, data for set
 */
export function session(key, data) {
	if (supported()) {
		if (typeof data === 'undefined') {
			try {
				return JSON.parse(window.sessionStorage.getItem(key));
			} catch (e) {
				return window.sessionStorage.getItem(key) || null;
			}
		} else {
			try {
				// might fail in random places
				if (data === null) {
					window.sessionStorage.removeItem(key);
				} else if (typeof data === 'string') {
					window.sessionStorage.setItem(key, data);
					window.app.log('STORAGE', `SESSION ${key} has been set:`, data);
				} else {
					const stringified = JSON.stringify(data);
					window.sessionStorage.setItem(key, stringified);
					window.app.log('STORAGE', `SESSION ${key} has been set:`, stringified);
				}
			} catch (e) {
				// Do nothing
			}
		}
	}

	return false;
}
