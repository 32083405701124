<template>
	<transition
		name="slide-down"
		mode="out-in">
		<div
			v-if="!foreverHidden"
			class="MemoryCallout"
			data-testid="memory-callout">
			<div
				class="Callout"
				:class="'Callout-' + type">
				<div
					class="MemoryCallout--closeBtn"
					data-testid="callout-close-btn"
					@click="close">
					<div class="iconFont-plus MemoryCallout--closeIcon"></div>
				</div>
				<p
					v-if="title"
					class="Callout--title">
					{{ title }}
				</p>
				<slot />
			</div>
		</div>
	</transition>
</template>

// -------------------------------------- SCRIPT ----------------------------------------------
<script>
	import * as Core from '@Core/index.js';
	import { useLanguageStore } from '@Core/store/language.js';

	export default {
		name: 'BlueprintMemoryCallout',

		//  ---------- PROPS ----------
		props: {
			/**
			 * property {string} id - unique for this popup (will be used for cookies)
			 * @namespace Core_Blueprint_MemoryCallout
			 * @property {string} id - unique for this popup (will be used for cookies)
			 */
			id: {
				type: String,
				required: true
			},
			/**
			 * property {string} [title] - to display for this callout
			 * @namespace Core_Blueprint_MemoryCallout
			 * @property {string} [title] - to display for this callout
			 */
			title: {
				type: String,
				required: false,
				default: null
			},
			/**
			 * property {string} [type='info'] - of callout (use type modifier you would set for standard callout)
			 * @namespace Core_Blueprint_MemoryCallout
			 * @property {string} [type='info'] - of callout (use type modifier you would set for standard callout)
			 */
			type: {
				type: String,
				required: false,
				default: 'info'
			}
		},

		//  ---------- SETUP ----------
		setup(props) {
			const language = useLanguageStore();
			const foreverHidden = Core.Vue.ref(false);

			// check if this item should be displayed
			Core.Vue.onBeforeMount(() => {
				foreverHidden.value = Core.Storage.local(`MemoryCallout-${props.id}`);
			});

			// close this element forver, set cookie
			/**
			 *
			 */
			function close() {
				foreverHidden.value = true;

				Core.Storage.local(`MemoryCallout-${props.id}`, true);
			}

			return { foreverHidden, close, language };
		}
	};
</script>

// -------------------------------------- STYLES ----------------------------------------------
<style lang="scss">
	@include block('MemoryCallout') {
		position: relative;

		& .Callout--title {
			z-index: 0;
		}

		@include element('closeBtn') {
			float: right;
			margin-top: 0;
			margin-left: var(--space-single);
			z-index: 1;
			position: relative;
		}

		@include element('closeIcon') {
			transform: rotate(45deg);
			display: inline-block;
			vertical-align: middle;
			margin-bottom: 2px;
			padding: 5px;
			font-size: var(--text-h3);
			transition: all var(--time-transition) ease-in-out;
			border-radius: 50%;
			&:hover,
			&:focus {
				cursor: pointer;
				background-color: var(--color-secondary);
			}
		}
	}
</style>
