/**
 * THIS IS A TEMPLATE FILE FOR THE BUILD PROCESS, NOT THE FINAL FILE TO BE USED WITHE THE CODE!
 * The below "marker" will be filled out every time build process runs
 * It is like a pre-processor which will find all *.route files and create dynamic router configuration
 */

import * as Core from '@Core/index.js'

const routes = [
{
path: '/:pathMatch(.*)*',
	component: () => import('../views/404/index.vue'),
	meta: {
		design: 'full',
		title: {
			"en": "404 Not Found",
			"lt": "404 Nerasta",
			"sv": "404 Hittades inte",
			"ro": "404 Nep găsit",
			"pl": "404 Nie znaleziono",
			"it": "404 Non trovato",
			"es": "404 No encontrado"
		}
	}
},
{
	path: '/article',
	components: {
		default:  () => import('../views/Article/index.vue')
	},
	meta: {
		title: {
			"en": "Articles",
			"lt": "Straipsniai",
			"sv": "Artiklar",
			"ro": "Articole",
			"pl": "Artykuły",
			"it": "Articoli",
			"es": "Artículos"
		}
	}
},
{
	path: '/article/:productSlug',
	components: {
		default:  () => import('../views/Article/singleArticle.vue')
	},
	meta: {
		title: {
			en: '%productTitle%'
		}
	}
},
{
	path: '/course',
	components: {
		default:  () => import('../views/Course/index.vue')
	},
	meta: {
		title: {
			en: 'Courses',
			"lt": "Kursai",
			"sv": "Kurser",
			"ro": "Cursuri",
			"pl": "Kursy",
			"it": "Corsi",
			"es": "Cursos"
		}
	}
},
{
	path: '/course/:productSlug',
	components: {
		default:  () => import('../views/Course/singleCourse.vue')
	},
	meta: {
		title: {
			en: '%productTitle%',
			pl: '%productTitle%'
		}
	}
},
{
	path: '/course/:productSlug/:lessonId',
	components: {
		default:  () => import('../views/Course/lesson.vue')
	},
	meta: {
		title: {
			en: '%lessonTitle%',
			pl: '%lessonTitle%'
		},
		permissions: "loggedIn"
	}
},
{
	path: '/course/:productSlug/:lessonId/:assignmentId',
	components: {
		default:  () => import('../views/Course/assignment.vue')
	},
	meta: {
		title: {
			en: '%assignmentTitle%',
			pl: '%assignmentTitle%'
		},
		permissions: "loggedIn"
	}
},
{
	path: '/download',
	components: {
		default:  () => import('../views/Download/index.vue')
	},
	meta: {
		title: {
			en: 'Digital Downloads',
			"lt": "Skaitmeniniai atsisiuntimai",
			"sv": "Digitala nedladdningar",
			"ro": "Descărcări digitale",
			"pl": "Pliki do pobrania",
			"it": "Download digitali",
			"es": "Descargas digitales"
		}
	}
},
{
	path: '/download/:productSlug',
	components: {
		default:  () => import('../views/Download/singleDownload.vue')
	},
	meta: {
		title: {
			en: '%productTitle%'
		}
	}
},
{
path: '/error',
	component: () => import('../views/error/index.vue'),
	meta: {
		design: 'full',
		title: {
			en: 'Error',
			"lt": "Klaida",
			"sv": "Fel",
			"ro": "Eroare",
			"pl": "Błąd",
			"it": "Errore",
			"es": "Error"
		}
	}
},
{
	path: '/',
	components: {
		default:  () => import('../views/Home/index.vue')
	},
	meta: {
		title: {
			en: 'Home',
			"lt": "Pagrindinis",
			"sv": "Hem",
			"ro": "Acasă",
			"pl": "Strona główna",
			"it": "Home",
			"es": "Inicio"
		}
	}
},

{
	path: '/login/reset-password',
	components: {
		default:  () => import('../views/Login/resetPassword.vue')
	},
	meta: {
		design: 'full',
		permissions: "loggedOut",
		hideHeader: true,
		title: {
			en: 'Reset Password',
			"lt": "Atstatyti slaptažodį",
			"sv": "Återställ lösenord",
			"ro": "Resetează parola",
			"pl": "Zresetuj hasło",
			"it": "Reimposta password",
			"es": "Restablecer contraseña"
		}
	}
},
{
	path: '/login/activate-user',
	components: {
		default:  () => import('../views/Login/activateUser.vue')
	},
	meta: {
		design: 'full',
		permissions: "loggedOut",
		hideHeader: true,
		title: {
			en: 'Activate User',
			"lt": "Aktyvuoti vartotoją",
			"sv": "Aktivera användare",
			"ro": "Activează utilizator",
			"pl": "Aktywuj użytkownika",
			"it": "Attiva utente",
			"es": "Activar usuario"
		}
	}
},
{
	path: '/page',
	redirect: '/',
	components: {
		default:  () => import('../views/Page/index.vue')
	},
	meta: {
		title: {
			en: 'Page',
			"lt": "Puslapis",
			"sv": "Sida",
			"ro": "Pagină",
			"pl": "Strona",
			"it": "Pagina",
			"es": "Página"
		}
	}
},
{
	path: '/page/:slug',
	components: {
		default:  () => import('../views/Page/index.vue')
	},
	meta: {
		title: {
			en: '%pageTitle%'
		}
	}
},
{
path: '/privacy-policy',
	component: () => import('../views/PrivacyPolicy/index.vue'),
	meta: {
		design: 'full',
		title: {
			"en": "Privacy Policy",
			"lt": "Privatumo politika",
			"sv": "Integritetspolicy",
			"ro": "Politica de confidențialitate",
			"pl": "Polityka prywatności",
			"it": "Informativa sulla privacy",
			"es": "Política de privacidad"
		}
	}
},
{
	path: '/user',
	components: {
		default:  () => import('../views/User/index.vue')
	},
	meta: {
		title: {
			en: 'User Profile',
			"lt": "Vartotojo profilis",
			"sv": "Användarprofil",
			"ro": "Profil utilizator",
			"pl": "Profil użytkownika",
			"it": "Profilo utente",
			"es": "Perfil de usuario"
		},
		permissions: "loggedIn"
	}
},
{
	path: '/user/products',
	components: {
		default:  () => import('../views/User/products.vue')
	},
	meta: {
		title: {
			en: 'My products',
			"lt": "Mano produktai",
			"sv": "Mina produkter",
			"ro": "Produsele mele",
			"pl": "Moje produkty",
			"it": "I miei prodotti",
			"es": "Mis productos"
		},
		permissions: "loggedIn"
	}
},
];

const router = Core.VueRouter.createRouter({
	history: Core.VueRouter.createWebHistory(),
	routes,
	scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    } else {
			return { top: 0 }
		}
  }
})

export default router