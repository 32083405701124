<template>
	<section>
		<ol
			class="ProgressSection"
			data-testid="progress-section">
			<li
				v-for="(item, index) in options"
				:key="index"
				data-testid="progress-section-item"
				:class="classForItem(index)"
				@click="backNavigation && navigateSection(index)">
				<span>{{ item }}</span>
			</li>
		</ol>
	</section>
</template>

// -------------------------------------- SCRIPT ----------------------------------------------
<script>
	import * as Core from '@Core/index.js';

	export default {
		name: 'BlueprintSectionProgress',

		//  ---------- PROPS ----------
		props: {
			/**
			 * property {boolean} options - to include as section names
			 * @namespace Core_Blueprint_SectionProgress
			 * @property {boolean} options - to include as section names
			 */
			options: {
				type: Array,
				required: true
			},

			/**
			 * property {number} [current=1] - index of the currently element, all previous will be set as done
			 * @namespace Core_Blueprint_SectionProgress
			 * @property {number} [current=1] - index of the currently element, all previous will be set as done
			 */
			current: {
				type: Number,
				required: false,
				default: 1
			},

			/**
			 * property {boolean} [backNavigation=false] - will allow to navigate back to previous sections
			 * @namespace Core_Blueprint_SectionProgress
			 * @property {boolean} [backNavigation=false] - will allow to navigate back to previous sections
			 */
			backNavigation: {
				type: Boolean,
				required: false,
				default: false
			},
			/**
			 * property {boolean} [showSteps=false] - will show the steps TEXT in the progress bar
			 * @namespace Core_Blueprint_SectionProgress
			 * @property {boolean} [showSteps=false] - will show the steps TEXT in the progress bar
			 */
			showSteps: {
				type: Boolean,
				required: false,
				default: false
			}
		},

		emits: ['change-section'],

		//  ---------- SETUP ----------
		setup(props, context) {
			const stepsOpacity = Core.Vue.ref(0);

			Core.Vue.onMounted(() => {
				stepsOpacity.value = props.showSteps ? 1 : 0;
			});
			/**
			 * will return correct class based on the index
			 * @param {number} index of an item
			 * @returns {string} classname for given section item
			 */
			function classForItem(index) {
				const correctCurrent = props.current - 1;

				if (correctCurrent === index) {
					return 'is-active';
				}
				if (index < correctCurrent) {
					return 'is-complete';
				}

				return '';
			}

			/**
			 * will navigate to the given section (if it's previous)
			 * @param {number} index of the clicked current item
			 */
			function navigateSection(index) {
				const correctCurrent = props.current - 1;
				const isNavigatingBack = correctCurrent > index;

				//Restrict the functionality to only going back, we don't want to allow forward navigation on section progress..
				if (isNavigatingBack) {
					context.emit('change-section', index + 1);
				}
			}

			return { classForItem, navigateSection, stepsOpacity };
		}
	};
</script>

// -------------------------------------- STYLES ----------------------------------------------
<style lang="scss">
	.ProgressSection {
		display: flex;
		justify-content: space-between;
		list-style: none;
		padding: 0;
		margin: 0 0 1rem 0;
	}
	.ProgressSection li {
		flex: 2;
		position: relative;
		padding: 0 0 14px 0;
		line-height: 1.5;
		color: var(--color-grey400);
		font-weight: 600;
		white-space: nowrap;
		overflow: visible;
		min-width: 0;
		text-align: center;
		border-bottom: 3px solid var(--color-grey700);
	}
	.ProgressSection li:first-child,
	.ProgressSection li:last-child {
		flex: 1;
	}

	.ProgressSection li:first-child {
		text-align: left;
	}
	.ProgressSection li:last-child {
		text-align: right;
	}
	.ProgressSection li:before {
		content: '';
		display: block;
		width: 12px;
		height: 12px;
		background-color: var(--color-grey700);
		border-radius: 50%;
		border: 3px solid var(--color-grey900);
		position: absolute;
		left: calc(50% - 6px);
		bottom: -7px;
		z-index: 3;
		transition: all 0.2s ease-in-out;
	}
	.ProgressSection li:first-child:before {
		left: 0;
	}
	.ProgressSection li:last-child:before {
		right: 0;
		left: auto;
	}
	.ProgressSection span {
		transition: opacity 0.3s ease-in-out;
	}
	.ProgressSection li:not(.is-active) span {
		opacity: v-bind(stepsOpacity);
	}

	.ProgressSection .is-complete:not(:first-child):after,
	.ProgressSection .is-active:not(:first-child):after {
		content: '';
		display: block;
		width: 100%;
		position: absolute;
		bottom: -3px;
		left: -50%;
		z-index: 2;
		border-bottom: 3px solid var(--color-primary);
	}
	.ProgressSection li:last-child span {
		width: 200%;
		display: inline-block;
		position: absolute;
		left: -100%;
	}

	.ProgressSection .is-complete:last-child:after,
	.ProgressSection .is-active:last-child:after {
		width: 200%;
		left: -100%;
	}

	.ProgressSection .is-complete:before {
		background-color: var(--color-greyStart);
		border-color: var(--color-primary);
	}

	.ProgressSection .is-complete {
		&:hover {
			cursor: pointer;
		}
	}

	.ProgressSection .is-active:before,
	.ProgressSection li:hover:before,
	.ProgressSection .is-hovered:before {
		background-color: var(--color-primary);
		border-color: var(--color-primary);
	}
	.ProgressSection li:hover:before,
	.ProgressSection .is-hovered:before {
		transform: scale(1.33);
	}

	.ProgressSection li:hover span,
	.ProgressSection li.is-hovered span {
		opacity: 1;
	}

	.ProgressSection:hover li:not(:hover) span {
		opacity: 0;
	}

	.ProgressSection .has-changes {
		opacity: 1 !important;
	}
	.ProgressSection .has-changes:before {
		content: '';
		display: block;
		width: 8px;
		height: 8px;
		position: absolute;
		left: calc(50% - 4px);
		bottom: -20px;
		background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%208%208%22%3E%3Cpath%20fill%3D%22%23ed1c24%22%20d%3D%22M4%200l4%208H0z%22%2F%3E%3C%2Fsvg%3E');
	}
</style>
