/**
 @module Core/Hash
 */

// -------------------------------------- PUBLIC METHODS ----------------------------------------------
/**
 * Finds hash value in URL by key
 * @param {string} key - what key you want to look for
 * @returns {string} value or null if does not exist
 */
export function get(key) {
	try {
		return location.hash.match(new RegExp(`${key}=([^&]*)`))[1];
	} catch (e) {
		return null;
	}
}

/**
 * Sets hash value in url using your key and value pair
 * @param {string} key what key you want to use
 * @param {string} [value=''] what value you want to set (not provided will unset it)
 */
export function set(key, value) {
	const currentHash = window.location.hash.replace('#', '');
	const currentItem = get(key);
	let newItem, newHash;

	if (value && value !== '') {
		newItem = `${key}=${value}`;
	} else {
		newItem = '';
	}

	if (currentItem === null) {
		// if object does not exist in hash yet.
		if (currentHash === '') {
			newHash = newItem; // if no items in hash at all
		} else {
			newHash = `${currentHash}&${newItem}`; // if some items exist already, just add &
		}
	} else {
		// just replace existing stuff
		if (value !== '') {
			newHash = currentHash.replace(`${key}=${currentItem}`, ''); // if new value set
		} else {
			newHash = currentHash.replace(`${key}=${currentItem}`, newItem); // if value not set (should be removed)
		}
	}

	if (newHash === '&') {
		newHash = '';
	}

	// If no hash values in URL just add empty string
	newHash = newHash === '' ? ' ' : `#${newHash}`;

	window.history.pushState(null, null, `${newHash}`);
}
