/**
 @module Student/LoginUtils
 */

import * as Core from '@Core/index.js';
import * as Store from '@Student/store/index.js';

/**
 * @function createOgMeta
 * @description This function is used to create og meta for the student login page.
 * @param {string} [type='other'] - The type of og meta to be created ['product', 'page', 'other']
 * @param {object} [dataObject] - dataObject
 */
export function createOgMeta(type = 'other', dataObject) {
	const metaData = {
		'og:title': null,
		'og:type': null,
		'og:image': null,
		'og:url': window.location.href,
		'og:description': null,
		'og:site_name': null,
		'twitter:card': 'summary_large_image'
	};

	// set if product
	if (type === 'product') {
		const school = Store.school.getDetails;
		metaData['og:title'] = dataObject.title;
		metaData['og:type'] = 'article';
		metaData['og:image'] =
			`${Core.Config.BUCKET_SCHOOL}/${school.id}/products/${dataObject.id}/${dataObject.image}`;
		metaData['og:description'] = dataObject.description;
		metaData['og:site_name'] = school.name;
	}

	// set if page
	if (type === 'page') {
		const school = Store.school.getDetails;
		metaData['og:title'] = dataObject.title;
		metaData['og:type'] = 'article';
		metaData['og:image'] =
			`${Core.Config.BUCKET_SCHOOL}/${school.id}/pages/${dataObject.id}/${dataObject.image}`;
		metaData['og:description'] = dataObject.title;
		metaData['og:site_name'] = school.name;
	}

	// set if other
	if (type === 'other') {
		const school = Store.school.getDetails;
		metaData['og:title'] = school.name;
		metaData['og:type'] = 'website';
		metaData['og:image'] = `${Core.Config.BUCKET_SCHOOL}/${school.id}/${school.homeImage}`;
		metaData['og:description'] = school.description;
		metaData['og:site_name'] = school.name;
	}

	// Set all the meta tags
	for (const key in metaData) {
		if (metaData[key]) {
			Core.Utils.setMetaTag(key, metaData[key]);
		}
	}
}
