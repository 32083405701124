<template>
	<transition-group name="fade">
		<blueprint-loader
			v-if="loading"
			key="loading"
			:full-size="true" />

		<core-design-full
			v-else-if="design === 'full'"
			key="full">
			<template #default>
				<router-view v-slot="{ Component }">
					<component :is="Component" />
				</router-view>
			</template>
		</core-design-full>

		<core-design-default
			v-else
			key="default">
			<template #default>
				<router-view v-slot="{ Component }">
					<component :is="Component" />
				</router-view>
			</template>
		</core-design-default>
	</transition-group>

	<core-error-handler key="errorHandle" />
	<core-notifications />
</template>

<script>
	import * as Core from '@Core/index.js';
	import * as Store from '@Student/store/index.js';
	import * as Initialize from '@Student/modules/Initialize/index';
	import RouterPermissions from '@Student/modules/RouterPermissions/index.js';

	export default {
		//  ---------- COMPONENTS ----------
		components: {
			'core-design-default': Core.cDesign.Default,
			'core-design-full': Core.cDesign.Full,
			'core-error-handler': Core.cErrorHandler,
			'core-notifications': Core.cNotifications
		},

		//  ---------- SETUP ----------
		setup() {
			const loading = Core.Vue.ref(false);
			const route = Core.VueRouter.useRoute();

			// Choose the right design template (default is "default")
			const router = Core.VueRouter.useRouter();
			const design = Core.Vue.ref('default');

			router.beforeEach(async (to, from, next) => {
				loading.value = true;

				await Initialize.start(false, router);
				const nextPagePermitted = RouterPermissions(to);

				if (nextPagePermitted === to.path) {
					design.value = to.meta.design || 'default'; // set template
					next();
				} else {
					// sometimes nextPagePermitted is not defined, but router correctly resolved the right path (like 404 functionality)
					if (!nextPagePermitted) {
						design.value = to.meta.design || 'default'; // set template
					}

					app.log(
						'ROUTER',
						'LOGGED IN: Router changed the initially requested path',
						to.path
					);
					next(nextPagePermitted);
				}
			});

			// after the route is loaded
			router.afterEach((to) => {
				loading.value = false;
				setTimeout(() => {
					document.title = `${Core.Utils.pageTitle(to.meta, route, Store)} | ${
						Store.school.getDetails.name
					}`; // browser title

					// set meta data
					const meta = document.getElementsByTagName('meta');
					meta.description.content = Store.school.getDetails.description;
				}, 500);
			});

			/**
			 * Global Event.trigger() event name 'USER_LOGGED_OUT' to handle unauthorized API responses.,
			 * @event USER_LOGGED_OUT
			 * @see Student
			 */
			Core.Event.on('USER_LOGGED_OUT', function () {
				router.push('/');
				Core.Event.trigger('NOTIFICATIONS', {
					type: 'danger',
					content: Store.language.string.Student.notificationLoggedOut,
					icon: 'iconFont-cross'
				});
				Initialize.reset();
			});

			return { Core, design, loading };
		}
	};
</script>

<style lang="scss">
	@import '@Core/styles/index';
</style>
