/**
 @module Core/Event
 */

const eventsHistory = {};

/**
 * Trigger a javascript event (publish)
 * @param {string} name - name of the event (follow agreed format
 * @param {*} data - payload you want to send with the event
 * @returns {boolean} if it worked
 */
function trigger(name, data) {
	if (!name) {
		window.app.log('EVENT', 'Trigger failed.');
		return false;
	} // trigger fails when 'name' not provided

	window.app.log('EVENT', name, data);
	let event;
	if (window.CustomEvent) {
		event = new window.CustomEvent(name, { detail: { data: data } });
	} else {
		event = document.createEvent('CustomEvent');
		event.initCustomEvent(name, true, false, { data: data });
	}

	eventsHistory[name] = data;
	document.dispatchEvent(event);
	return true;
}

/**
 * Listen for an event (subscribe)
 * @param {string} name - name of the event (follow agreed format
 * @param {Function} callback - your callback method
 * @returns {Function} that will remove the set events if called
 */
function on(name, callback) {
	if (!callback) {
		// on fails when 'callback' not provided
		window.app.log('Event', `on() failed for ${name}. No callback provided.`);
		return false;
	}

	const listener = function (e) {
		callback(e.type, e.detail.data);
	};

	document.addEventListener(name, listener);

	return function () {
		document.removeEventListener(name, listener);
	};
}

/**
 * Checks the history of events for a given name
 * @param {string} name - The name of the event you want to check.
 * @returns {*} event or false
 */
function history(name) {
	if (typeof eventsHistory[name] !== 'undefined') {
		return eventsHistory[name];
	} else {
		return false;
	}
}

/* EXPOSE ------------------------------------------ */
export { trigger, on, history };
