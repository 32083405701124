<template>
	<div
		class="BpAccordion"
		data-testid="blueprint-accordion">
		<div
			v-for="(item, index) in options"
			:key="index"
			class="accordion-item">
			<button>
				<span class="accordion-title">{{ item.title }}</span
				><span class="icon"></span>
			</button>
			<div class="accordion-content">
				<div v-html="item.content"></div>
			</div>
		</div>
	</div>
</template>

// -------------------------------------- SCRIPT ----------------------------------------------
<script>
	export default {
		name: 'BlueprintAccordion',

		//  ---------- PROPS ----------
		props: {
			/**
			 * property {Array} options - array with title & content objects
			 * @namespace Core_Blueprint_Accordion
			 * @property {Array} options - array with title & content objects
			 */
			options: {
				type: Array,
				required: true
			}
		}
	};
</script>

// -------------------------------------- STYLES ----------------------------------------------
<style lang="scss">
	.BpAccordion {
		.accordion-item {
			border-bottom: 1px solid var(--color-grey800);
			button[aria-expanded='true'] {
				border-bottom: 1px solid var(--color-primary);
			}

			&:last-child {
				border-bottom: none;
			}
		}
		button {
			position: relative;
			display: block;
			text-align: left;
			width: 100%;
			padding: 1em 0;
			font-weight: var(--weight-bold);
			border: none;
			background: none;
			outline: none;
			&:hover,
			&:focus {
				cursor: pointer;
				color: var(--color-primary);
				&::after {
					cursor: pointer;
					color: var(--color-primary);
					border: 1px solid var(--color-primary);
				}
			}
			.accordion-title {
				padding: 1em 1.5em 1em 0;
			}
			.icon {
				display: inline-block;
				position: absolute;
				top: 18px;
				right: 0;
				width: 22px;
				height: 22px;
				border: 1px solid;
				border-radius: 22px;
				&::before {
					display: block;
					position: absolute;
					content: '';
					top: 9px;
					left: 5px;
					width: 10px;
					height: 2px;
					background: currentColor;
				}
				&::after {
					display: block;
					position: absolute;
					content: '';
					top: 5px;
					left: 9px;
					width: 2px;
					height: 10px;
					background: currentColor;
				}
			}
		}
		button:focus {
			color: var(--color-primary);
			.icon {
				display: none;
			}
			+ .accordion-content {
				opacity: 1;
				max-height: 600px;
				transition: all var(--time-transition) linear;
				will-change: opacity, max-height;
			}
		}
		.accordion-content {
			opacity: 0;
			max-height: 0;
			overflow: hidden;
			transition:
				opacity var(--time-transition) linear,
				max-height var(--time-transition) linear;
			will-change: opacity, max-height;
			div {
				font-size: 1rem;
				font-weight: var(--weight-regular);
				margin-bottom: var(--space-double);
			}
		}
	}
</style>
