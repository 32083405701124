<template>
	<div
		ref="targetEl"
		data-testid="lazy"
		:style="`min-height:${fixedMinHeight ? fixedMinHeight : minHeight}px`">
		<slot v-if="shouldRender" />
	</div>
</template>

<script>
	/**
	 * Lazy component (blueprint-lazy).
	 * Allows to lazy-render content and should be use when page is a bit longer or contains a lot of HTML components.
	 * Renders regular DIV so can be used instead of it. Remember to use min width.
	 * ex: <blueprint-lazy class="col-lg-24" :min-height="300"></>
	 * @namespace Core_Blueprint_Lazy
	 */

	import * as Core from '@Core/index.js';
	import { useIntersectionObserver } from '@vueuse/core';

	export default {
		name: 'BlueprintLazy',

		// ---------- PROPS ----------
		props: {
			/**
			 * property {boolean} [renderOnIdle] - will render when browser is on idle
			 * @namespace Core_Blueprint_Lazy
			 * @property {boolean} [renderOnIdle=false] - will render when browser is on idle
			 */
			renderOnIdle: {
				required: false,
				type: Boolean,
				default: false
			},

			/**
			 * property {boolean} [unrender=false] - when it is out of view
			 * @namespace Core_Blueprint_Lazy
			 * @property {boolean} [unrender=false] - when it is out of view
			 */
			unrender: {
				required: false,
				type: Boolean,
				default: false
			},

			/**
			 * property {number} [minHeight=200] - minimum height of the element (in px)
			 * @namespace Core_Blueprint_Lazy
			 * @property {number} [minHeight=200] - minimum height of the element (in px)
			 */
			minHeight: {
				required: false,
				type: Number,
				default: 200
			},

			/**
			 * property {number} [unrenderDelay=10000] - delay before we unrender the component after scroll-away
			 * @namespace Core_Blueprint_Lazy
			 * @property {number} [unrenderDelay=10000] - delay before we unrender the component after scroll-away
			 */
			unrenderDelay: {
				required: false,
				type: Number,
				default: 10000
			}
		},

		//  ---------- SETUP ----------
		setup(props) {
			const shouldRender = Core.Vue.ref(false);
			const targetEl = Core.Vue.ref();
			const fixedMinHeight = Core.Vue.ref(0);
			let unrenderTimer;
			let renderTimer;

			const { stop } = useIntersectionObserver(
				targetEl,
				([{ isIntersecting }]) => {
					if (isIntersecting) {
						// perhaps the user re-scrolled to a component that was set to unrender. In that case stop the unrendering timer
						clearTimeout(unrenderTimer);
						// if we're dealing unrendering lets add a waiting period of 200ms before rendering. If a component enters the viewport and also leaves it within 200ms it will not render at all. This saves work and improves performance when user scrolls very fast
						renderTimer = setTimeout(
							() => (shouldRender.value = true),
							props.unrender ? 200 : 0
						);
						shouldRender.value = true;
						if (!props.unrender) {
							stop();
						}
					} else if (props.unrender) {
						// if the component was set to render, cancel that
						clearTimeout(renderTimer);
						unrenderTimer = setTimeout(() => {
							fixedMinHeight.value = targetEl.value?.clientHeight;
							shouldRender.value = false;
						}, props.unrenderDelay);
					}
				},
				{
					rootMargin: '600px'
				}
			);

			if (props.renderOnIdle) {
				onIdle(() => {
					shouldRender.value = true;
					if (!props.unrender) {
						stop();
					}
				});
			}

			/**
			 * Allows to set any min-height after it has been fixed with unrender functionality
			 * Unrender takes element height at the point of removing it from DOM.
			 * @param {number} value of the height we should change to
			 */
			function updateFixedMinHeight(value) {
				fixedMinHeight.value = value;
			}

			return { targetEl, shouldRender, fixedMinHeight, updateFixedMinHeight };
		}
	};

	/* --------------- PRIVATE METHODS ------------------ */
	/**
	 * On idle detection method for internal use
	 * @private
	 * @namespace Core_Blueprint_Lazy
	 * @param {Function} cb callback method
	 */
	function onIdle(cb = () => {}) {
		if ('requestIdleCallback' in window) {
			window.requestIdleCallback(cb);
		} else {
			setTimeout(() => {
				Core.Vue.nextTick(cb);
			}, 300);
		}
	}
</script>
