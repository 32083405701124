<template>
	<div
		class="row align-items-center justify-content-center BpToggle"
		:data-testid="id + '-toggle'">
		<input
			:id="state.id"
			ref="checkboxInput"
			v-model="computedValue"
			class="BpToggle--input col-12"
			:class="{ 'BpToggle--checked': computedValue }"
			:name="state.id"
			:value="checkedValue || true"
			type="checkbox"
			:required="required"
			:aria-required="required"
			data-testid="toggle" />
		<label
			:for="state.id"
			class="BpToggle--label col-12"
			data-testid="toggle-label">
			{{ label }}
			<span
				v-if="state.tooltip"
				class="Tooltip Tooltip-bottom"
				data-testid="toggle-tooltip"
				:data-tooltip="state.tooltip"
				><span class="Tooltip-icon BpForms--tooltip"></span
			></span>
		</label>
	</div>
</template>

// -------------------------------------- SCRIPT --------------------------
<script>
	import * as Core from '@Core/index.js';
	import * as Helpers from './helpers.js';

	export default {
		name: 'BlueprintToggle',

		//  ---------- PROPS ----------
		props: {
			/**
			 * property {string} id - unique id
			 * @namespace Core_Blueprint_Toggle
			 * @property {string} id - unique id
			 */
			id: {
				type: String,
				required: true
			},

			/**
			 * property {boolean | string | number} [value=false] - tell if it should be checked on start
			 * @namespace Core_Blueprint_Toggle
			 * @property {boolean | string | number} [value=false] - tell if it should be checked on start
			 */
			value: {
				type: [Boolean, String, Number],
				required: false,
				default: false
			},

			/**
			 * property {string | number} [value] - checkedValue what to return once it is checked (only works for collections/array)
			 * @namespace Core_Blueprint_Toggle
			 * @property {string | number} [value] - checkedValue what to return once it is checked (only works for collections/array)
			 */
			checkedValue: {
				type: [String, Number],
				required: false,
				default: undefined
			},

			/**
			 * property {string} label - label, or title
			 * @namespace Core_Blueprint_Toggle
			 * @property {string} label - label, or title
			 */
			label: {
				type: String,
				required: false,
				default: null
			},

			/**
			 * property {string} [tooltip] - tooltip content
			 * @namespace Core_Blueprint_Toggle
			 * @property {string} [tooltip] - tooltip content
			 */
			tooltip: {
				type: String,
				required: false,
				default: null
			},

			/**
			 * property {string} [required=false] is this field required?
			 * @namespace Core_Blueprint_Toggle
			 * @property {string} [required=false] is this field required?
			 */
			required: {
				type: Boolean,
				required: false
			}
		},

		//  ---------- EMITS ----------
		emits: ['update:value'],

		//  ---------- SETUP ----------
		setup(props, context) {
			// reactive props, that's all
			const state = Core.Vue.computed(() => {
				return {
					...props, // make all props reactive
					type: 'checkbox'
				};
			});

			// internal values only (can be set from inside or outside)
			const internal = Core.Vue.reactive({
				currentMsgType: state.value.msgType,
				value: state.value.value
			});

			// this field's update process looks a bit different and it's using computedValue set & get methods
			// or it will break when you have multiple-choice checkbox (array value)
			const computedValue = Core.Vue.computed({
				get() {
					return props.value;
				},
				set(newValue) {
					context.emit('update:value', newValue);
					Core.Vue.nextTick(() => validate());
				}
			});

			// allow update to internals from outside via props
			Core.Vue.watch(
				() => [props.msgType],
				(msgTypeNew, msgTypePrev) => {
					if (msgTypeNew !== msgTypePrev) {
						internal.currentMsgType = msgTypeNew;
					}
				}
			);

			/* -------------------- UPDATE & VALIDATE FUNCTIONALITY ---------------------- */
			/**
			 * validate the field
			 * @returns {boolean} validation result
			 */
			function validate() {
				return Helpers.validators(state, internal);
			}

			return {
				state,
				internal,
				computedValue,
				validate
			};
		}
	};
</script>

<style lang="scss">
	@include block('BpToggle') {
		@include element('input') {
			position: relative;
			height: 1.5rem;
			width: 3rem;
			cursor: pointer;
			appearance: none;
			border-radius: 2rem;
			background-color: var(--color-grey900);
			transition: all 0.3s ease;
			border: 1px solid var(--color-grey800);
			box-shadow: var(--color-grey800) 0px 2px 5px 0px;
			&::before {
				content: '';
				position: absolute;
				left: 0.2rem;
				top: 0.15rem;
				height: 1.1rem;
				width: 1.1rem;
				border-radius: 50%;
				background-color: var(--color-grey600);
				transition: all 0.3s ease-in-out;
			}
		}
		@include element('checked') {
			&::before {
				transform: translateX(125%);
				background-color: var(--color-stateSuccess);
			}
		}
		@include element('label') {
			&:hover {
				cursor: pointer;
			}
		}
	}
</style>
