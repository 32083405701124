<template>
	<div class="DesignEdge">
		<div class="container-fluid DesignEdge">
			<div class="row DesignEdge--wrapperRow">
				<div class="col-lg-4 col-md-6 DesignEdge--navigation">
					<slot name="nav"></slot>
				</div>

				<div class="col-lg-20 col-md-18 DesignEdge--content">
					<slot name="content"></slot>
				</div>
			</div>
		</div>
	</div>
</template>

// -------------------------------------- SCRIPT ----------------------------------------------
<script>
	/**
	 * Design template for our applications. Default screen without any columns or extra stuff. Just one, main container but with footer and header
	 * @vue-prop {*} slot:unnamed slot for your main content or component
	 * @namespace Core_Design_Full
	 */

	export default {
		name: 'DesignEdge'
	};
</script>

// -------------------------------------- STYLES ----------------------------------------------
<style lang="scss">
	@include block('DesignEdge') {
		height: 100vh;

		@include element('navigation') {
			background-color: var(--color-secondary);
			padding-top: var(--space-single);
		}

		@include element('content') {
			padding-top: var(--space-single);
			padding-bottom: var(--space-double);
		}

		@include element('wrapperRow') {
			height: 100%;
			min-height: calc(100% - 125px);
		}
	}
</style>
